"use client";
import { cn } from "@evolve-storefront/ui/helpers/styles";
import { useWindowSize } from "@evolve-storefront/ui/hooks/use-responsive";
import { BREAKPOINTS } from "@evolve-storefront/ui/tailwind.config";
import { ChevronUp } from "lucide-react";
import type { ReactNode } from "react";
import { useId, useState } from "react";

export type LinkItem = {
	url: string;
	label: string;
};

type Props = {
	label: ReactNode;
	children?: ReactNode;
};

export const MobileAccordion = ({ label, children }: Props): ReactNode => {
	const windowSize = useWindowSize(BREAKPOINTS);
	const isMobile = windowSize.upToMobile;
	const id = useId();

	const [isOpen, setIsOpen] = useState(!isMobile);

	return (
		<div className="max-md:border-b max-md:py-1">
			<h3
				id={`panel-${id}-heading`}
				className="mb-0 font-bold text-sm leading-heading md:mb-2 md:text-base"
			>
				<span className="max-md:hidden">{label}</span>
				<button
					type="button"
					className="flex w-full items-center justify-between max-md:p-4 md:hidden"
					onClick={() => setIsOpen(!isOpen)}
					aria-expanded={isOpen}
					aria-controls={`panel-${id}-content`}
				>
					<span>{label}</span>
					<ChevronUp
						className={cn("transition-all md:hidden", !isOpen && "rotate-180")}
					/>
				</button>
			</h3>
			<section
				id={`panel-${id}-content`}
				className={cn(
					"transition-all max-md:grid max-md:px-4",
					isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]",
				)}
				aria-labelledby={`panel-${id}-heading`}
				aria-hidden={!isOpen}
			>
				<div
					className={cn(
						"transition-all duration-300 max-md:overflow-hidden",
						isOpen
							? "visible opacity-100 delay-100"
							: "max-md:invisible max-md:opacity-0",
					)}
				>
					<div className="max-md:mb-4">{children}</div>
				</div>
			</section>
		</div>
	);
};
