"use client";

import { Icon } from "@evolve-storefront/ui/base/Icon/Icon";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@evolve-storefront/ui/components/Dialog/Dialog";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import type { Locale } from "~/lib/i18n/types";
import type { TopMenuItemFragment } from "../Navigation.fragments";
import type { HeaderInfoPagesFragment } from "../TopBar";
import LanguageSwitch from "./LanguageSwitch";
import { MainMenu } from "./MainMenu";
import { SubMenu } from "./SubMenu";

type Props = {
	locale: Locale;
	topMenuItems: ResultOf<typeof TopMenuItemFragment>[];
	infoPages?: ResultOf<typeof HeaderInfoPagesFragment>["infoPages"];
	className?: string;
};

export function HamburgerNavigation({
	locale,
	topMenuItems,
	infoPages,
	className,
}: Props): ReactNode {
	const t = useTranslations("HamburgerNavigation");
	const pathname = usePathname();
	const [selectedItem, setSelectedItem] =
		useState<ResultOf<typeof TopMenuItemFragment>>();
	const [languageOpen, setLanguageOpen] = useState(false);

	const topMenuItem = useMemo(
		() => topMenuItems.find((item) => item.id === selectedItem?.id),
		[selectedItem, topMenuItems],
	);

	useEffect(() => {
		setSelectedItem(undefined);
		setLanguageOpen(false);
	}, [pathname]);

	const Content = () => {
		if (languageOpen) {
			return (
				<LanguageSwitch
					locale={locale}
					backLabel={t("language-back")}
					onBack={() => setLanguageOpen(false)}
				/>
			);
		}

		if (topMenuItem) {
			return (
				<SubMenu
					topMenuItem={topMenuItem}
					onBack={() => setSelectedItem(undefined)}
				/>
			);
		}

		return (
			<MainMenu
				topMenuItems={topMenuItems}
				onMainMenuItemSelect={setSelectedItem}
				onSelectLanguage={() => {
					setLanguageOpen(true);
				}}
				infoPages={infoPages}
				locale={locale}
			/>
		);
	};

	return (
		<Dialog>
			<DialogTrigger aria-label={t("open-menu")} className={className}>
				<Icon icon="menu" className="size-6" />
			</DialogTrigger>

			<DialogContent
				className="flex flex-col gap-0"
				aria-label={t("accessibility-name")}
			>
				<Content />
			</DialogContent>
		</Dialog>
	);
}
