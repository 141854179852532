import type { Salutation } from "@evolve-packages/site-config";
import type { CountryCode } from "libphonenumber-js";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { ZodIssueCode, z } from "zod";
import { CustomErrorKeys } from "./errorKeys";
import { validateVatId } from "./vatId";

// Validates phone number and transforms it to E.164 format
export const transformPhoneNumber = <
	T extends { country: CountryCode; phone: string },
>(
	countryCodes: readonly string[],
	data: T,
	ctx: z.RefinementCtx,
) => {
	if (!data.country || !countryCodes.includes(data.country)) {
		// Don't transform when invalid country code given
		return data;
	}

	if (!isValidPhoneNumber(data.phone, data.country)) {
		// Validate phone number validity
		ctx.addIssue({
			path: ["phone"],
			keys: [CustomErrorKeys.InvalidPhoneNumber],
			code: ZodIssueCode.unrecognized_keys,
		});

		return z.NEVER;
	}

	return {
		...data,
		phone: parsePhoneNumber(data.phone, data.country).format("E.164"),
	};
};

// Validates VAT ID and transforms it to a consistent format
export const transformVatId = async <
	T extends { country?: string; vatId?: string },
>(
	countryCodes: readonly string[],
	data: T,
	ctx: z.RefinementCtx,
) => {
	if (!data.country || !countryCodes.includes(data.country)) {
		// Don't transform when invalid country code given
		return data;
	}

	if (data.country && data.vatId) {
		const result = await validateVatId(data.country, data.vatId);

		if (result?.valid) {
			// Valid vatId response
			return {
				...data,
				vatId: result.countryCode + result.vatNumber,
			};
		} else if (result && !result.valid) {
			// Invalid vatId response, raise error
			ctx.addIssue({
				path: ["vatId"],
				keys: [CustomErrorKeys.InvalidVatNumber],
				code: ZodIssueCode.unrecognized_keys,
			});

			return z.NEVER;
		}
	}

	return data;
};

// Validates that the date of birth is not in the future
export const transformDateOfBirth = <
	T extends { dateOfBirth?: Date | undefined },
>(
	data: T,
	ctx: z.RefinementCtx,
) => {
	const now = new Date();

	if (data.dateOfBirth && data.dateOfBirth > now) {
		// Invalid date of birth, raise error
		ctx.addIssue({
			path: ["dateOfBirth"],
			keys: [CustomErrorKeys.InvalidDateOfBirth],
			code: ZodIssueCode.unrecognized_keys,
		});
		return z.NEVER;
	}

	return data;
};

export function transformSalutation(salutation?: Salutation | null) {
	if (salutation) {
		return ["M", "F"].includes(salutation) ? salutation : undefined;
	}

	return undefined;
}
