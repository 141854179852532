export const countries = ["NL", "BE", "DE", "FR"] as const;
export type Country = (typeof countries)[number];

export const languages = ["en", "nl", "fr", "de"] as const;
export type Language = (typeof languages)[number];

export type Locale = `${Language}-${Country}`;

export const salutations = ["MR", "MS"] as const;
export type Salutation = (typeof salutations)[number];
