import { cn } from "@evolve-storefront/ui/helpers/styles";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { isValue } from "@labdigital/toolkit";
import type { ReactNode } from "react";
import { Link } from "~/components/Link";
import { cmsPreviewAttributes } from "~/lib/cms-preview";
import { getMenuItemLabel, getMenuItemURL } from "~/lib/helpers/menuLink";
import type { TopMenuItemFragment } from "../Navigation.fragments";

type Props = {
	menuItem: ResultOf<typeof TopMenuItemFragment>;
};

export const SubNavigation = ({ menuItem }: Props): ReactNode => {
	return (
		<div className="container columns-4 gap-x-4 text-base">
			{menuItem.subMenuItems?.filter(isValue).map((subMenuItem, i) => (
				<ul
					key={subMenuItem.label || i}
					className={cn(
						"p-0",
						(subMenuItem.subMenuItems?.length ?? 0) < 12 &&
							"break-inside-avoid",
					)}
					{...cmsPreviewAttributes(subMenuItem)}
				>
					{subMenuItem.subMenuItems ? (
						<>
							<li className={cn(i > 0 && "mt-8")}>
								<Link
									href={getMenuItemURL(subMenuItem)}
									className="inline-flex items-center gap-1 font-medium text-lg hover:underline"
								>
									<span>{getMenuItemLabel(subMenuItem)}</span>
								</Link>
							</li>

							{subMenuItem.subMenuItems
								.filter(isValue)
								.map((subMenuLink, i) => (
									<li
										key={subMenuLink.label || i}
										{...cmsPreviewAttributes(subMenuLink)}
										className={cn("text-base", "mt-2")}
									>
										<Link
											href={getMenuItemURL(subMenuLink)}
											className="hover:text-main hover:underline"
										>
											{getMenuItemLabel(subMenuLink)}
										</Link>
									</li>
								))}
						</>
					) : (
						<li
							className={cn(
								"flex items-center gap-1 font-medium",
								i > 0 && "mt-8",
							)}
						>
							<Link
								href={getMenuItemURL(subMenuItem)}
								className="inline-flex items-center"
							>
								{getMenuItemLabel(subMenuItem)}
							</Link>
						</li>
					)}
				</ul>
			))}
		</div>
	);
};
