"use client";

import { Button } from "@evolve-storefront/ui/base/Button";
import { Input } from "@evolve-storefront/ui/base/Input/Input";
import { Search } from "lucide-react";
import { useTranslations } from "next-intl";
import { useSearchParams } from "next/navigation";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { useRouter } from "~/lib/i18n/navigation";

export function SearchBar(): ReactNode {
	const searchParams = useSearchParams();
	const router = useRouter();
	const t = useTranslations("SearchBar");

	// Pressing '/' if not already in a form element will focus the search input
	useKeyDown((event: KeyboardEvent) => {
		if (event.key === "/") {
			if (
				event.target instanceof HTMLInputElement ||
				event.target instanceof HTMLSelectElement ||
				event.target instanceof HTMLTextAreaElement
			) {
				return;
			}
			event.preventDefault();

			document
				.querySelector<HTMLInputElement>("input[type=search][name=q]")
				?.focus();
		}
	});

	const handleSubmitSearch = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const input = event.currentTarget.elements.namedItem(
			"q",
		) as HTMLInputElement;

		if (input.value.length < 3) {
			return;
		}
		router.push({
			pathname: "/search",
			query: { q: input.value },
		});

		document
			.querySelector<HTMLAnchorElement>('[aria-label="Product listing"] a')
			?.focus();
	};

	return (
		<form
			action="/search" // if js is disabled, the form will still work
			onSubmit={handleSubmitSearch}
			className="relative w-full"
			aria-labelledby="search-button"
		>
			<Input
				name="q"
				type="search"
				defaultValue={searchParams.get("q") ?? ""}
				placeholder={t("placeholder")}
				className="h-12 rounded-full"
				required
			/>
			<Button
				id="search-button"
				className="absolute right-0 m-0.5 rounded-full px-6"
				variant="tertiary"
				type="submit"
				title={t("search")}
			>
				<Search className="size-6" />
			</Button>
		</form>
	);
}

export const useKeyDown = (handler: (event: KeyboardEvent) => void): void =>
	useEffect(() => {
		window.addEventListener("keydown", handler);
		return () => window.removeEventListener("keydown", handler);
	}, [handler]);
