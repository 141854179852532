import { Icon } from "@evolve-storefront/ui/base/Icon/Icon";
import { cn } from "@evolve-storefront/ui/helpers/styles";
import type { ReactNode } from "react";

type Props = {
	label: ReactNode;
	trigger: () => void;
	icon?: "forward" | "collapse" | "expand";
	className?: string;
	strong?: boolean;
};

const ActionTile = ({
	label,
	trigger,
	icon,
	strong,
	className,
}: Props): ReactNode => {
	let iconStyle = undefined;
	if (icon === "collapse") {
		iconStyle = "-rotate-90";
	} else if (icon === "expand") {
		iconStyle = "rotate-90";
	}

	return (
		<button
			type="button"
			className={cn(
				"group flex w-full items-center justify-between gap-4 border-gray-200 border-b",
				strong ? "font-medium" : null,
				className,
			)}
			onClick={trigger}
		>
			<div className="py-5 pl-6 group-hover:text-brand">{label}</div>
			{icon && (
				<div className="pr-6">
					<Icon
						icon="arrow-right"
						className={cn("block size-4 transition-transform", iconStyle)}
					/>
				</div>
			)}
		</button>
	);
};
export default ActionTile;
