import { contentfulDataAttributes } from "@evolve-storefront/contentful-preview/component";
import { getPreviewID as getPreviewIDContentful } from "@evolve-storefront/contentful-preview/server";
import { storyblokDataAttributes } from "@evolve-storefront/storyblok-preview/component";
import { getPreviewID as getPreviewIDStoryblok } from "@evolve-storefront/storyblok-preview/server";

type Attributes = {
	__typename: string;
	id: string;
};

const EVOLVE_CMS = process.env.NEXT_PUBLIC_EVOLVE_CMS ?? "contentful";

export const cmsPreviewAttributes = (
	data: Attributes,
): Record<string, string> | undefined => {
	switch (EVOLVE_CMS) {
		case "contentful":
			return contentfulDataAttributes(data);
		case "storyblok":
			return storyblokDataAttributes(data);
	}
};

export const getContentPreviewID = async (): Promise<string | undefined> => {
	switch (EVOLVE_CMS) {
		case "contentful":
			return getPreviewIDContentful();
		case "storyblok":
			return getPreviewIDStoryblok();
		default:
			return undefined;
	}
};
