"use client";

import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import ActionTile from "./ActionTile";

type Props = {
	children: ReactNode;
	label: string;
	initialOpen?: boolean;
};

const Collapse = ({ children, label, initialOpen }: Props): ReactNode => {
	const [isOpen, setOpen] = useState(false);

	useEffect(() => {
		setOpen(initialOpen ?? false);
	}, [initialOpen]);

	return (
		<>
			<ActionTile
				label={label}
				trigger={() => {
					setOpen(!isOpen);
				}}
				icon={isOpen ? "collapse" : "expand"}
				strong
			/>
			{isOpen && children}
		</>
	);
};

export default Collapse;
