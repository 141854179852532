import { cn } from "@evolve-storefront/ui/helpers/styles";
import type { ReactNode } from "react";
import type { Options } from "react-markdown";
import Markdown from "react-markdown";

// TODO: Move to reusable base component
export const FormDescription = ({
	className,
	children,
	...props
}: Options & { id: string }): ReactNode => (
	<Markdown className={cn("prose", className)} {...props}>
		{children}
	</Markdown>
);
