"use client";

import { Icon } from "@evolve-storefront/ui/base/Icon/Icon";
import type { ReactNode } from "react";
import { useAlternates } from "~/lib/i18n/alternates";
import { useStoreConfig } from "~/lib/store-config/context";
import { IconFlag } from "../../IconFlag/IconFlag";
import TopBar from "./TopBar";

type Props = {
	locale: string;
	onBack: () => void;
	backLabel: string;
};

const LanguageSwitch = ({
	locale: currentLocaleString,
	onBack,
	backLabel,
}: Props): ReactNode => {
	const currentLocale = new Intl.Locale(currentLocaleString);
	const { locales } = useStoreConfig();
	const alternates = useAlternates(locales);

	return (
		<div className="max-h-screen overflow-y-scroll">
			<TopBar onBack={onBack} backLabel={backLabel} />
			{locales
				.map((locale) => new Intl.Locale(locale))
				.map(
					(locale) =>
						[
							locale,
							alternates.find((a) => a.locale.toString() === locale.toString()),
						] as const,
				)
				.map(([locale, alternate], index) => (
					<a
						className="block border-gray-200 border-b px-6 py-5 font-medium hover:text-brand"
						key={index}
						href={alternate?.href}
					>
						<span className="flex w-full items-center gap-3 hover:underline">
							<IconFlag locale={locale} />

							<span className="capitalize">
								{new Intl.DisplayNames([locale], { type: "language" }).of(
									locale.language,
								)}
							</span>

							{currentLocale.toString() === locale.toString() ? (
								<Icon icon="check" className="ml-auto h-4 w-5 text-tg-green" />
							) : undefined}
						</span>
					</a>
				))}
		</div>
	);
};

export default LanguageSwitch;
