import { Icon } from "@evolve-storefront/ui/base/Icon/Icon";
import { DialogClose } from "@evolve-storefront/ui/components/Dialog/Dialog";
import type { ReactNode } from "react";
import type { ReactElement } from "react";

type Props = {
	onBack?: () => void;
	backLabel?: ReactNode;
};

const TopBar = ({ onBack, backLabel }: Props): ReactElement => (
	<div className="flex h-14 justify-end border-gray-200 border-b">
		{onBack && (
			<button
				type="button"
				className="flex size-14 grow items-center font-bold"
				onClick={onBack}
			>
				<Icon icon="arrow-left" className="mr-4 ml-5 size-4" />
				{backLabel}
			</button>
		)}
		<DialogClose asChild>
			<button
				type="button"
				className="flex size-14 items-center justify-center"
			>
				<Icon icon="chevron-left" className="size-6" />
				<span className="sr-only">Close</span>
			</button>
		</DialogClose>
	</div>
);

export default TopBar;
