import { countries, salutations } from "@evolve-packages/site-config";
import { checkVAT, countries as vatCountries } from "jsvat";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { z } from "zod";
import { customZodIssue, requiredZodIssue } from "./errorKeys";
import { transformSalutation } from "./transformers";

// Generic fields

export const emailField = z
	.string()
	.email()
	.min(2)
	.max(50)
	.toLowerCase()
	.default("");
const passwordField = z.string().min(2).max(64).default("");

// 0) Account Login

export const accountSearchSchema = z.object({
	email: z.string().email().min(2).max(50).toLowerCase().default(""),
});

export const accountLoginSchema = z.object({
	email: emailField,
	password: passwordField,
});

export const phoneSchema = z
	.object({
		country: z.enum(countries).default("NL"),
		phone: z.string().min(1).max(20).default(""),
	})
	.transform((data, ctx) => {
		if (!countries.includes(data.country)) {
			// Don't transform when invalid country code given
			return z.NEVER;
		} else if (!isValidPhoneNumber(data.phone, data.country)) {
			// Validate phone number validity
			ctx.addIssue({
				message: "INVALID_PHONE_NUMBER",
				path: ["phone"],
				code: "custom",
			});

			return z.NEVER;
		}

		return {
			...data,
			phone: parsePhoneNumber(data.phone, data.country).format("E.164"),
		};
	});

export const accountPasswordSchema = z.object({
	createAccount: z.literal(true),
	email: emailField,
	password: passwordField,
});

export const addressSchema = z.object({
	salutation: z
		.enum(salutations)
		.transform(transformSalutation)
		.optional()
		.nullable(),
	givenName: z.string().trim().min(1).max(50).default(""),
	familyName: z.string().trim().min(1).max(50).default(""),
	country: z.enum(countries).default("NL"),
	streetName: z.string().min(1).default(""),
	streetNumber: z.string().min(1).default(""),
	streetNumberSuffix: z.string().default("").optional(),
	postalCode: z.string().min(1).default(""),
	city: z.string().min(1).default(""),
	company: z.string().default("").optional(),
});

export const contactSchema = z.object({
	salutation: z
		.enum(salutations)
		.transform(transformSalutation)
		.optional()
		.nullable(),
	givenName: z.string().trim().min(1).max(50).default(""),
	familyName: z.string().trim().min(1).max(50).default(""),
});

export const customerAddressSchema = contactSchema
	.and(addressSchema)
	.and(phoneSchema);

// 3) Payment
export const paymentSchema = z.union([
	z.discriminatedUnion("paymentMethod", [
		z.object({
			paymentMethod: z.literal("ideal"),
			issuer: z.string().max(64),
		}),
		z.object({
			paymentMethod: z.literal("klarna"),
			dateOfBirth: z.date(),
		}),
	]),
	z.object({
		paymentMethod: z.string(),
		issuer: z.string().max(64).optional(),
	}),
]);

// 4) Additional

export const newsletterSchema = z.object({
	email: z.string().email().min(2).max(50).toLowerCase().default(""),
});

export const accountDeleteSchema = z
	.object({
		password: passwordField,
	})
	.superRefine((val, ctx) => {
		if (!val.password.length) {
			// VAT number is required
			ctx.addIssue(requiredZodIssue(["password"]));
		}
	});

export const accountSchema = z
	.object({
		vatNumber: z.string().trim().default(""),
		billingAddress: customerAddressSchema,
	})
	.transform((data, ctx) => refineForVatNumber(data, ctx));

export const refineForVatNumber = <
	T extends { billingAddress: { country: string }; vatNumber: string },
>(
	data: T,
	ctx: z.RefinementCtx,
) => {
	if (data.billingAddress.country !== "NL" && !data.vatNumber.length) {
		// VAT number is required
		ctx.addIssue(requiredZodIssue(["vatNumber"]));
	}

	const countriesResult = vatCountries.filter((country) =>
		Object.values(country.codes).includes(data.billingAddress.country),
	);

	if (countriesResult.length === 0) {
		// Don't transform when an invalid country code is given
		return data;
	}

	// Validate VAT number
	const vatResult = data.vatNumber && checkVAT(data.vatNumber, countriesResult);

	if (vatResult && !vatResult.isValid) {
		ctx.addIssue(customZodIssue("InvalidVatNumberFormat", ["vatNumber"]));

		return data;
	}

	return {
		...data,
		vatNumber: vatResult ? vatResult.value : undefined,
	};
};

export const passwordResetSchema = z.object({
	password: passwordField,
});
export const accountPasswordUpdateSchema = z
	.object({
		password: passwordField,
		newPassword: passwordField,
	})
	.superRefine((val, ctx) => {
		if (val.password === val.newPassword) {
			ctx.addIssue(customZodIssue("PasswordsCantMatch", ["newPassword"]));
		}
	});

export const registerFormSchema = z
	.object({
		email: emailField,
		password: passwordField,
	})
	.and(contactSchema)
	.superRefine((val, ctx) => {
		if (!val.password.length) {
			ctx.addIssue(requiredZodIssue(["password"]));
		}
	});
