"use client";

import { cn } from "@evolve-storefront/ui/helpers/styles";
import type { ResultOf } from "@graphql-typed-document-node/core";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import type { ReactNode } from "react";
import type { RefObject } from "react";
import { useEffect, useRef } from "react";
import { Link } from "~/components/Link";
import { cmsPreviewAttributes } from "~/lib/cms-preview";
import { getMenuItemLabel, getMenuItemURL } from "~/lib/helpers/menuLink";
import { usePathname, useRouter } from "~/lib/i18n/navigation";
import type { TopMenuItemFragment } from "../Navigation.fragments";
import { SubNavigation } from "./DesktopSubNavigation";

type Props = {
	topMenuItems: ResultOf<typeof TopMenuItemFragment>[];
};

const DesktopNavigation = ({ topMenuItems }: Props): ReactNode => {
	const ref = useRef<HTMLDivElement>(null);
	const router = useRouter();
	useCloseMenuOnNavigation(ref);

	return (
		// we use first-child because NavigationMenu.List wraps in a div that cannot be classed
		<NavigationMenu.Root className="relative hidden bg-gray-100 lg:block">
			<NavigationMenu.List className="container flex p-0">
				{topMenuItems.map((mainMenuItem, i) => (
					<NavigationMenu.Item key={i} {...cmsPreviewAttributes(mainMenuItem)}>
						<NavigationMenu.Trigger
							className={cn(
								"group inline-flex px-4 py-3 font-bold text-sm leading-6 xl:text-base",
							)}
							asChild
							{...clickHandlers(router)}
						>
							<button type="button">
								<Link
									href={getMenuItemURL(mainMenuItem)}
									className="pointer-events-none group-aria-expanded:pointer-events-auto"
								>
									{getMenuItemLabel(mainMenuItem)}
								</Link>
								{(mainMenuItem.subMenuItems?.length ?? 0) > 0 && (
									<NavigationMenu.Content
										ref={ref}
										className={cn(
											"absolute top-0 left-0 grid max-h-[calc(100lvh-10.5rem)] w-full overflow-y-auto overscroll-contain bg-background py-6",
											"data-[motion=from-end]:animate-fadeIn data-[motion=from-start]:animate-fadeIn data-[motion=to-end]:animate-fadeOut data-[motion=to-start]:animate-fadeOut",
										)}
									>
										<SubNavigation menuItem={mainMenuItem} />
									</NavigationMenu.Content>
								)}
							</button>
						</NavigationMenu.Trigger>
					</NavigationMenu.Item>
				))}

				<NavigationMenu.Indicator className="top-[calc(100%-3px)] z-10 flex h-[3px] w-full origin-center bg-black px-3 transition-all" />
			</NavigationMenu.List>
			<NavigationMenu.Viewport
				className={cn(
					"absolute top-full h-[var(--radix-navigation-menu-viewport-height)] w-full bg-background transition-all",
					"after:-z-10 after:pointer-events-none after:fixed after:inset-0 after:top-40 after:h-screen after:bg-black/40",
				)}
			/>
		</NavigationMenu.Root>
	);
};

// We don't use the existing NavigationMenuLink because that closes the menu on click.
// Instead, we want to close the menu on navigation.
const useCloseMenuOnNavigation = (ref: RefObject<HTMLDivElement | null>) => {
	const pathname = usePathname();

	useEffect(() => {
		if (ref.current) {
			ref.current.dispatchEvent(
				new CustomEvent("navigationMenu.rootContentDismiss", {
					bubbles: true,
					cancelable: true,
				}),
			);
		}
	}, [pathname, ref]);
};

// The first 300ms events are by default disable in NavigationMenu, so we need
// to handle the click ourselves
// Inspired by https://github.com/radix-ui/primitives/issues/2326#issuecomment-1698778861
// Note that long term it might make sense to inline NavigationMenu and handle
// the click in the component itself
const clickHandlers = (router: ReturnType<typeof useRouter>) => {
	let disableTimer: NodeJS.Timeout | undefined = undefined;
	return {
		onClick: (e: React.MouseEvent<HTMLElement>) => {
			if (disableTimer) {
				const path = e.currentTarget.querySelector("a")?.href;
				if (path) {
					router.push(path, { scroll: true });
				}
				e.preventDefault();
				e.stopPropagation();
			}
		},
		onPointerEnter: () => {
			clearTimeout(disableTimer);
			disableTimer = setTimeout(() => {
				disableTimer = undefined;
				return;
			}, 300);
		},
	};
};

export default DesktopNavigation;
