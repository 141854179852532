"use client";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Check } from "lucide-react";
import { useLocale } from "next-intl";
import type { ReactNode } from "react";
import { Link } from "~/components/Link";
import { useAlternates } from "~/lib/i18n/alternates";
import { useStoreConfig } from "~/lib/store-config/context";
import { IconFlag } from "../IconFlag/IconFlag";

// TODO: Check if we can get rid of Client specific hooks and move use client to leaf components
export const LanguageSwitcherDesktop = (): ReactNode => {
	const currentLocale = new Intl.Locale(useLocale());
	const { locales } = useStoreConfig();
	const alternates = useAlternates(locales);

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger
				aria-label={
					new Intl.DisplayNames([currentLocale], {
						type: "language",
					}).of(currentLocale.toString()) || ""
				}
				className="overflow-hidden rounded-none border-x px-4 py-1 hover:border-brand"
			>
				<IconFlag
					locale={currentLocale}
					className="size-6 rounded-full object-cover"
					width={24}
					height={24}
				/>
			</DropdownMenu.Trigger>

			<DropdownMenu.Content
				align="end"
				className="z-10 mt-2 flex min-w-[300px] flex-col rounded border border-gray-200 bg-white shadow-md"
			>
				{alternates &&
					alternates.length > 0 &&
					locales
						.map((locale) => new Intl.Locale(locale))

						.map(
							(locale) =>
								[
									locale,
									alternates.find(
										(a) => a.locale.toString() === locale.toString(),
									),
								] as const,
						)
						.map(([locale, alternate], index) => {
							return (
								<DropdownMenu.Item asChild key={index}>
									<Link
										className="flex w-full items-center gap-3 rounded-none p-2 ring-offset-0"
										href={alternate?.href ?? "/"}
									>
										<IconFlag locale={locale} />

										<span className="capitalize">
											{new Intl.DisplayNames([locale], { type: "language" }).of(
												locale.region === "BE"
													? locale.toString()
													: locale.language,
											)}
										</span>

										{locale.toString() === currentLocale.toString() ? (
											<Check className="ml-auto h-3 w-4 text-tg-green" />
										) : undefined}
									</Link>
								</DropdownMenu.Item>
							);
						})}
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	);
};

// For the Suspense fallback
export const LanguageSwitcherDesktopFallback = (): ReactNode => {
	const locale = new Intl.Locale(useLocale());
	return (
		<IconFlag locale={locale} className="h-4 w-[22px]" width={16} height={16} />
	);
};
